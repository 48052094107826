import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CategoryService } from '../category-list/category.service';
import { CategoryModel } from './categort.model';
import { SubCategoryModel } from './models/subcategory';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

 // @Input() categories;
  @Input() categoryParentId;
  categories:SubCategoryModel[]=[];

  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories;

  constructor(
    private categoryService: CategoryService ,
  
  ) {}

  ngOnInit(): void {
    this.categoryService.getAllSubCategorys()
    .subscribe(data=>{
        this.categories=data;
        this.mainCategories=data;
        //console.log(this.Vacationss)
    })
}
  // public ngDoCheck() {
  //   if(this.categories && !this.mainCategories) {
  //     this.mainCategories = this.categories.filter(category => category.parentId == this.categoryParentId); 
  //   }
  // }

  // public stopClickPropagate(event: any){
  //   if(window.innerWidth < 960){
  //     event.stopPropagation();
  //     event.preventDefault();
  //   }    
  // }

  public changeCategory(event){
    this.change.emit(event);
  }


}