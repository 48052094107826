import { WikalaService } from 'src/app/shared/services/wikala.service';
import { Component, OnInit } from '@angular/core';
import { Data, AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import { UserService } from 'src/app/shared/services/user.service';
import { ClientService } from 'src/app/pages/account/services/user.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = [ 'الجنيةالمصري'];
  public currency:any;
  public isLogin:boolean=false;
  public flags = [
    { name:'مصر', image: 'assets/images/flags/500px-Flag_of_Egypt.svg.png' },
    // { name:'English', image: 'assets/images/flags/gb.svg' },
    // { name:'German', image: 'assets/images/flags/de.svg' },
    // { name:'French', image: 'assets/images/flags/fr.svg' },
    // { name:'Russian', image: 'assets/images/flags/ru.svg' },
    // { name:'Turkish', image: 'assets/images/flags/tr.svg' },
   
  ]
  public flag:any;

  public settings: Settings;
  constructor(private clientService:ClientService,public appSettings:AppSettings, public appService:AppService,public userService:UserService,private WikalaService:WikalaService) { 
    this.settings = this.appSettings.settings; 
    this.isLogin=this.userService.isLoggedIn(); 
 // var tokin  =localStorage.getItem('token')
  if(localStorage.getItem('token')!=null){
    this.isLogin=true;
  }
  } 

  ngOnInit() {
    debugger;
    this.currency = this.currencies[0];
    this.flag = this.flags[0];   
    this.isLogin=this.userService.isLoggedIn(); 
  }

  public changeCurrency(currency){
    this.currency = currency;
  }

  public changeLang(flag){
    this.flag = flag;
  }
  logOut(){
    this.userService.logout();
    location.reload();


  }
  

}
