import { ProductDetails } from "./product-details.model";
import { ProductImage } from "./product-image.model";
import { ProductPrice } from "./product-price.model";

export class ProductsModel {
    supplierProductId?: number;
    productName?:       string;
    description?:       string;
    imgUrl?:            string;
    subCategoryId?:     number;
    subCategoryName?:   string;
    brandId?:           number;
    brandName?:         string;
    oldPrice?:          number;
    newPrice?:          number;
    ratingsCount?:      number;
    discount ?:         number;
    ratingsValue?:      number;
    availibilityCount?: number;
    sublierId?:         number;
    productId?:         number;
    quantaty?:          number;
    productPriceId?:     number;
    images?:ProductImage[];
    weight?:string;
    packageContents?:string;
    requiredQuantity?:number;
    AvailibilityCount?:number;
    prices?:      ProductPrice[];
    productDetaisl?:ProductDetails[]
    constructor(sp?:ProductsModel){
        sp=sp?sp:{};
        
        this.supplierProductId=sp.supplierProductId?sp.supplierProductId:0;
        this.requiredQuantity=sp.requiredQuantity?sp.requiredQuantity:0;
        this.AvailibilityCount=sp.AvailibilityCount?sp.AvailibilityCount:0;
        this.sublierId=sp.sublierId?sp.sublierId:0;
        this.productId=sp.productId?sp.productId :0;
        this.productName=sp.productName?sp.productName:'';
        this.weight=sp.weight?sp.weight:'';
        this.oldPrice=sp.oldPrice?sp.oldPrice:0;
        this.description=sp.description?sp.description:'';
        this.imgUrl=sp.imgUrl?sp.imgUrl :'';
        this.subCategoryId=sp.subCategoryId?sp.subCategoryId:0;
        this.brandId=sp.brandId?sp.brandId:0;
        this.brandName=sp.brandName?sp.brandName:'';
        this.packageContents=sp.packageContents?sp.packageContents:'';
        
        this.newPrice=sp.newPrice?sp.newPrice:0;
        this.ratingsCount=sp.ratingsCount?sp.ratingsCount :0;
        this.ratingsValue=sp.ratingsValue?sp.ratingsValue:0;
        this.availibilityCount=sp.availibilityCount?sp.availibilityCount:0;
        this.quantaty=sp.quantaty?sp.quantaty:0;
        this.discount=sp.discount?sp.discount:0;
        this.productPriceId=sp.productPriceId?sp.productPriceId:0;
        this.images = sp.images ? sp.images : [];
        this.prices = sp.prices ? sp.prices : [];
        this.productDetaisl = sp.productDetaisl ? sp.productDetaisl : [];
        
    }
}
