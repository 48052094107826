import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';
import { UserService } from 'src/app/shared/services/user.service';
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseUrl = environment.DevApiUrl;
  complateOrdersRate = 0;
  preparingOrdersRate = 0;
  shippingOrdersRate = 0;
  deliveredOrdersRate = 0;
  complateOrdersCount = 0;
  preparingOrdersCount = 0;
  shippingOrdersCount = 0;
  deliveredOrdersCount = 0;

  orderCount=0;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient,private userService:UserService) {
    if(userService.loggedIn){
        this.GetCurrentData()
        .subscribe(result=>{
            this.user = result;
            if(this.user.orders.length > 0){
                this.orderCount = this.user.orders.length;
                this.user.orders.forEach(element => {
                    if(element.stutasId == 1){
                        this.preparingOrdersCount += 1;
                        this.preparingOrdersRate = (this.preparingOrdersCount/this.orderCount)*100
                    }
                    if(element.stutasId == 2){
                        this.shippingOrdersCount += 1;
                        this.shippingOrdersRate = (this.shippingOrdersCount/this.orderCount)*100

                    }
                    if(element.stutasId == 3){
                        this.deliveredOrdersCount += 1;
                        this.deliveredOrdersRate = (this.deliveredOrdersCount/this.orderCount)*100

                    }
                    if(element.stutasId == 4){
                        this.complateOrdersCount += 1;
                        this.complateOrdersRate = (this.complateOrdersCount/this.orderCount)*100

                    }
                });
            }
        })
    }  
 
  }
  user:UserModel={};

  GetCurrentData(): Observable<UserModel> {
    return this.http
      .get<UserModel>(`${this.baseUrl}/User/GetCurrentData`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  Update(): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/User/Update`,this.user,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

 
}
