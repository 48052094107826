import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularDeviceInformationService } from 'angular-device-information';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';

@Component({
    selector: 'mobile-links',
    templateUrl: 'mobile-links.component.html',
    styleUrls: ['mobile-links.component.scss']
})
export class MobileLinksComponent implements OnInit {
    public config: SwiperConfigInterface = {};
    constructor(public appService:AppService, 
                public dialogRef: MatDialogRef<MobileLinksComponent>,
                private deviceInformationService: AngularDeviceInformationService,) { }
                appURL='';
                OS='';
    ngOnInit() { 
      this.OS = this.deviceInformationService.getDeviceInfo().os
      if(this.OS == "Android"){
        this.appURL="https://play.google.com/store/apps/details?id=net.wikala.wikala_app&pcampaignid=web_share"
      }
      else if(this.OS == "iOS"){
        this.appURL="https://apps.apple.com/eg/app/wikala-%D9%88%D9%83%D8%A7%D9%84%D8%A9/id6474852126"
      }
    }
  
    
  
    public close(): void {
      this.dialogRef.close();
    }
  
  }