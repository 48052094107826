import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItems = [ 
    new SidenavMenu (1, 'الرئسية', '/', null, null, false, 0),
    new SidenavMenu (2, 'موبايلات وتابلت', null, null, null, true, 0), 
    new SidenavMenu (3, 'موبايلات', '/products/mobiles-and-tablets/mobiles', null, null, false, 2),
    new SidenavMenu (4, 'تابلت', "/products/mobiles-and-tablets/tablets", null, null, false, 2),
    new SidenavMenu (5, 'اكسسوارت الموبيل', "/products/mobiles-and-tablets/mobile-accessorie", null, null, false, 2),
    new SidenavMenu (6, 'إلكترونيات', null, null, null, true, 0),
    new SidenavMenu (7, 'كمبيوتر', null, null, null, true, 6),
    new SidenavMenu (8, 'اجهزة كمبيوتر سطح المكتب','/products/electronics/computer/desktop-computers' , null, null, false, 7),
    new SidenavMenu (9, 'لابتوب','/products/electronics/computer/laptop' , null, null, false, 7),
    new SidenavMenu (10, 'أدوات كمبيوتر','/products/electronics/computer/computer-tools' , null, null, false, 7),
    new SidenavMenu (11, 'الصوت والفيديو', null, null, null, true, 6),
    new SidenavMenu (12, 'سماعات الرأس','/products/electronics/audio-and-video/headphones' , null, null, false, 11),
    new SidenavMenu (13, 'مكبرات الصوت','/products/electronics/audio-and-video/amplifiers' , null, null, false, 11),
    new SidenavMenu (14, 'أجهزة عرض','/products/electronics/audio-and-video/projectors' , null, null, false, 11),
    new SidenavMenu (15, 'انظمة صوت السيارات','/products/electronics/audio-and-video/car-audio-systems' , null, null, false, 11),
    new SidenavMenu (16, 'الكاميرات', null, null, null, true, 6),
    new SidenavMenu (17, 'كاميرات ديجيتال احترافية','/products/electronics/cameras/professional-digital-cameras' , null, null, false, 16),
    new SidenavMenu (18, 'عدسات','/products/electronics/cameras/lenses' , null, null, false, 16),
    new SidenavMenu (19, 'اكسسوارت التصوير ','/products/electronics/cameras/photography-accessories' , null, null, false, 16),
    new SidenavMenu (20, 'فلاش','/products/electronics/cameras/flash' , null, null, false, 16),
    new SidenavMenu (21, 'تلفزيونات', '/products/electronics/televisions', null, null, false, 6),
    new SidenavMenu (22, 'المنزل', null, null, null, true, 0),
    new SidenavMenu (23, 'ألأثاث', null, null, null, true, 22),
    new SidenavMenu (24, 'طاولات', '/products/home/furniture/tables', null, null, false, 23),
    new SidenavMenu (25, 'الكراسي', '/products/home/furniture/chairs', null, null, false, 23),
    new SidenavMenu (26, 'أثاث مكتبي', '/products/home/furniture/office-furniture', null, null, false, 23),
    new SidenavMenu (27, 'ديكور المنزل', '/products/home/home-decoration', null, null, false, 22),
    new SidenavMenu (28, 'المطبخ', '/products/home/kitchen', null, null, false, 22),
    new SidenavMenu (29, 'السرير والحمام', '/products/home/bed-and-bathroom', null, null, false, 22),

    new SidenavMenu (30, 'أزياء', null, null, null, true, 0),
    new SidenavMenu (31, 'أزياء حريمي', null, null, null, true, 30),
    new SidenavMenu (32, 'ملابس حريمي', '/products/fashion/women-fashion/women-clothing', null, null, false, 31),
    new SidenavMenu (33, 'احذية حريمي', '/products/fashion/women-fashion/women-shoes', null, null, false, 31),
    new SidenavMenu (34, 'اكسسوارات حريمي', '/products/fashion/women-fashion/women-accessories', null, null, false, 31),
    new SidenavMenu (35, 'فساتين', '/products/fashion/women-fashion/dresses', null, null, false, 31),
    new SidenavMenu (36, 'شنط حريمي', '/products/fashion/women-fashion/women-bags', null, null, false, 31),
    new SidenavMenu (37, 'لانجري', '/products/fashion/women-fashion/lingerie', null, null, false, 31),
    new SidenavMenu (38, 'أزياء رجالي', null, null, null, true, 30),
    new SidenavMenu (39, 'ملابس رجالي', '/products/fashion/mens-fashion/mens-clothing', null, null, false, 38),
    new SidenavMenu (40, 'احذية رجالي', '/products/fashion/mens-fashion/men-shoes', null, null, false, 38),
    new SidenavMenu (41, 'اكسسوارات رجالي', '/products/fashion/mens-fashion/men-accessories', null, null, false, 38),
    new SidenavMenu (42, 'اطفال', null, null, null, true, 30),
    new SidenavMenu (43, 'الاولاد', '/products/fashion/children/the-boys', null, null, false, 42),
    new SidenavMenu (44, 'البنات', '/products/fashion/children/the-girls', null, null, false, 42),
    new SidenavMenu (45, 'جزم اولاد', '/products/fashion/children/boys-boots', null, null, false, 42),
    new SidenavMenu (46, 'جزم بنات', '/products/fashion/children/girls-boots', null, null, false, 42),
    new SidenavMenu (47, 'محجبات', '/products/fashion/Veiled', null, null, false, 30),

    new SidenavMenu (48, 'ألصحة والجمال', null, null, null, true, 0),
    new SidenavMenu (49, 'الشعر', null, null, null, true, 48),
    new SidenavMenu (50, 'شامبو', '/products/health-beauty/hair/shampoo', null, null, false, 49),
    new SidenavMenu (51, 'تلوين الشعر', '/products/health-beauty/hair/hair-coloring', null, null, false, 49),
    new SidenavMenu (52, 'ادوات قص الشعر', '/products/health-beauty/hair/hair-clippers', null, null, false, 49),
    new SidenavMenu (53, 'بلسم', '/products/health-beauty/hair/conditioner', null, null, false, 49),


    new SidenavMenu (54, 'عطور', null, null, null, true, 48),
    new SidenavMenu (55, 'عطور رجالي', '/products/health-beauty/Perfumes/men-perfumes', null, null, false, 54),
    new SidenavMenu (56, 'عطور حريمي', '/products/health-beauty/Perfumes/women-perfumes', null, null, false, 54),


    new SidenavMenu (57, 'العناية الشخصية', null, null, null, true, 48),
    new SidenavMenu (58, 'حلاقة وازالة الشعر', '/products/health-beauty/Personal-care/shave-and-remove-hair', null, null, false, 57),
    new SidenavMenu (59, 'العناية بالبشرة', '/products/health-beauty/Personal-care/skin-care', null, null, false, 57),


    new SidenavMenu (60, 'الجمال', null, null, null, true, 48),
    new SidenavMenu (61, 'اكسسوارات ميك اب', '/products/health-beauty/beauty/makeup-accessories', null, null, false, 60),
    new SidenavMenu (62, 'الكل', '/products/health-beauty/beauty', null, null, false, 60),

    new SidenavMenu (63, 'أجهزة منزلية', null, null, null, true, 0),
    new SidenavMenu (64, 'أجهزة كهربائية', null, null, null, true, 63),
    new SidenavMenu (65, 'ألمكيفات', '/products/appliances/electric-machines/air-conditioners', null, null, false, 64),
    new SidenavMenu (66, 'غسالات', '/products/appliances/electric-machines/washers', null, null, true, 64),
    new SidenavMenu (67, 'ثلاجات', '/products/appliances/electric-machines/refrigerators', null, null, false, 64),
    new SidenavMenu (68, 'أفران وبوتجازات', '/products/appliances/electric-machines/ovens-and-stoves', null, null, false, 64),
    new SidenavMenu (69, 'مكانس', '/products/appliances/electric-machines/ovens-and-stoves', null, null, false, 64),

    new SidenavMenu (70, 'ألأجهزة المنزلية', null, null, null, true, 63),
    new SidenavMenu (71, 'مراوح', '/products/appliances/household-appliance/fans', null, null, false, 70),
    new SidenavMenu (72, 'مكاوي', '/products/appliances/household-appliance/Ironsn', null, null, false, 70),
    new SidenavMenu (73, 'مدافئ', '/products/appliances/household-appliance/heaters', null, null, false, 70),
    new SidenavMenu (74, 'ميكرويف', '/products/appliances/household-appliance/microwave', null, null, false, 70),
    new SidenavMenu (75, 'السخانات', '/products/appliances/household-appliance/heaters', null, null, false, 70),

    new SidenavMenu (76, 'اجهزة تحضير الطعام', '/products/appliances/food-preparation-equipment', null, null, false, 63),
    new SidenavMenu (77, 'ادوات تجهيز المنزل', '/products/appliances/home-preparation-tools', null, null, false, 63),

    new SidenavMenu (78, 'ألعناية بالاطفال', null, null, null, true, 0),
    new SidenavMenu (79, 'حفاضات', null, null, null, true, 78),
    new SidenavMenu (81, 'بامبرز', '/products/children-care/furniture/tables', null, null, false, 79),
    new SidenavMenu (82, 'فاين', '/products/children-care/furniture/fain', null, null, false, 79),
    new SidenavMenu (83, 'الكل', '/products/children-care/furniture', null, null, false, 79),

    new SidenavMenu (84, 'معدات اطفال ', '/products/children-care/baby-equipment', null, null, false, 78),
    new SidenavMenu (85, 'الاستحمام والعناية بالبشرة ', '/products/children-care/bathing-and-skin-care', null, null, false, 78),
    new SidenavMenu (86, 'العاب اطفال ', '/products/children-care/Toys', null, null, false, 78),
    new SidenavMenu (87, 'اكسسوارات اطفال ', '/products/children-accessories', null, null, false, 78),

    new SidenavMenu (88, 'أدوات رياضية', '/products/sports-tool', null, null, false, 0),
    new SidenavMenu (89, 'ألعاب', '/products/games', null, null, false, 0),
    new SidenavMenu (90, 'تواصل معنا', '/contact', null, null, false, 0),

]