import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { CartService } from 'src/app/shared/services/cart.service';
import { ProductModel } from 'src/app/pages/products/models/product-model';
import { CartModel } from 'src/app/pages/cart/cart.model';
import { ProductsModel } from 'src/app/pages/products/models/products-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { WikalaService } from '../services/wikala.service';
import { PagesComponent } from 'src/app/pages/pages.component';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  public CarLolaclproducts: Array<ProductsModel> = [];
  @Input() product: ProductsModel;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count:number = 1;
  public align = 'center center';
  public myCartProduct:CartModel;
  public isLogin:boolean=false;
  
  constructor(public Cartservice :CartService, public wikalaservice: WikalaService,public appService:AppService, public snackBar: MatSnackBar, private spinner: NgxSpinnerService) { 
    if(localStorage.getItem('token')!=null){
      this.isLogin=true;
    }
  }

  ngOnInit() {
    
    // if(this.product){
    //   if(this.product.cartCount > 0){
    //     this.count = this.product.cartCount;
    //   }
    // }  
    // let refreshCart= new PagesComponent();
    // refreshCart.ngOnInit();
    this.layoutAlign(); 
  }

  public layoutAlign(){
    if(this.type == 'all'){
      this.align = 'space-between center';
    }
    else if(this.type == 'wish'){
      this.align = 'start center';
    }
    else{
      this.align = 'center center';
    }
  }

  public increment(product:any){
  product.requiredQuantity ++;
    let MyProducts= JSON.parse(localStorage.getItem('cartProducts'));
    MyProducts.forEach(element => {
      if(element.supplierProductId==product.supplierProductId){
        if(element.availibilityCount>=1){
          element.requiredQuantity=element.requiredQuantity+1;
          // product.requiredQuantity=element.requiredQuantity;
          element.availibilityCount=element.availibilityCount-1;
          localStorage.setItem("cartProducts", JSON.stringify(MyProducts));
          this.changeQuantity(element);

          this.snackBar.open('تمت الاضافة بنجاح ','×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
               
        }
        else       this.snackBar.open('عذرا لايوجد عدد متاح بالمخزن' ,'×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

      
      }

    });
    let wishList= JSON.parse(localStorage.getItem('wishlistProducts'));
    wishList.forEach(element => {
      if(element.supplierProductId==product.supplierProductId){
        if(element.availibilityCount>=1){
          element.requiredQuantity=element.requiredQuantity+1;
          // product.requiredQuantity=element.requiredQuantity;
          element.availibilityCount=element.availibilityCount-1;
          localStorage.setItem("wishlistProducts", JSON.stringify(wishList));
          this.changeQuantity(element);

          this.snackBar.open('تمت الاضافة بنجاح ','×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
               
        }
        else       this.snackBar.open('عذرا لايوجد عدد متاح بالمخزن' ,'×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

      
      }

    });
  }

  public decrement(product:any){
    product.requiredQuantity --;
    let MyProducts= JSON.parse(localStorage.getItem('cartProducts'));
    MyProducts.forEach(element => {
      if(element.supplierProductId==product.supplierproductid){

          element.requiredQuantity=element.requiredQuantity-1;
          // product.requiredQuantity=element.requiredQuantity;
          element.availibilityCount=element.availibilityCount+1;
          localStorage.setItem("cartProducts", JSON.stringify(MyProducts));
          this.changeQuantity(element);
          this.snackBar.open('تمت التعديل بنجاح ','×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
               
        

      
      }

    });
    
    let wishList= JSON.parse(localStorage.getItem('wishlistProducts'));
    wishList.forEach(element => {
      if(element.supplierProductId==product.supplierproductid){
        if(element.availibilityCount>=1){
          element.requiredQuantity=element.requiredQuantity-1;
          // product.requiredQuantity=element.requiredQuantity-1;
          this.count=element.requiredQuantity;
          element.availibilityCount=element.availibilityCount+1;
          localStorage.setItem("wishlistProducts", JSON.stringify(wishList));
          this.changeQuantity(element);

          this.snackBar.open('تمت التعديل بنجاح ','×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
               
        }

      
      }

    });
  }

  public addToCompare(product:Product){
    this.appService.addToCompare(product);
  }

  public addToWishList(product:any){
    product.requiredQuantity=1;
   this.wikalaservice.addProductToWishlist(product)
  }

  public addToCart(product:ProductsModel){
    if(product.availibilityCount > 0){
      this.myCartProduct= new CartModel();
      this.myCartProduct.productId=product.productId;
      this.myCartProduct.supplierProductId=product.supplierProductId;
      this.myCartProduct.producPriceId=product.productPriceId;
      this.myCartProduct.requiredQuantity=product.requiredQuantity;
      this.myCartProduct.productMainImage=product.imgUrl;
      this.myCartProduct.newPrice=product.newPrice;
      this.myCartProduct.oldPrice=product.oldPrice;
      this.myCartProduct.productName=product.productName;
      this.myCartProduct.availibilityCount=product.availibilityCount-1;
      this.myCartProduct.supplierId = product.sublierId;
      // console.log('model before save',this.myCartProduct)
      // this.Cartservice.Save(this.myCartProduct)
      // .subscribe(result =>{
      //   console.log(result);
      // })
      this.wikalaservice.addProductToCart(this.myCartProduct);
    }
    else{
      this.snackBar.open('لا يوجد كمية متاحة من هذا المنتج' , '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }




    // console.log(product)
    
   
//   if(this.isLogin==false)
//   {
//  var myprodut= localStorage.getItem('myProducts');
//  myprodut.forEach(element => {
  
//  });
//     localStorage.setItem('myProducts', JSON.stringify(this.CarLolaclproducts));
//   }
// else{
//   this.myCartProduct= new CartModel();
//   this.myCartProduct.productId=product.productId;
//   this.myCartProduct.supplierProductId=product.supplierProductId;
//   this.myCartProduct.producPriceId=product.productPriceId;
//   this.myCartProduct.requiredQuantity=1;
//   this.cartService.addTocart(this.myCartProduct)  
//        .subscribe(data => { 
//          this.snackBar.open('تمت اضافة المنتج الي السلة بنجاح', '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
//        },  
//        error => { 
//          setTimeout(() => {
//            /** spinner ends after 3 seconds */
//            this.spinner.hide();
//          }, 100)
//          this.snackBar.open('حدث خطاء برجاء اعادة المحاولة', '×', { panelClass: 'error', verticalPosition: 'top', duration: 6000 });
//        }); 
// }
  
    
    // let currentProduct = this.appService.Data.cartList.filter(item=>item.id == product.id)[0];
    // if(currentProduct){
    //   if((currentProduct.cartCount + this.count) <= this.product.availibilityCount){
    //     product.cartCount = currentProduct.cartCount + this.count;
    //   }
    //   else{
    //     this.snackBar.open('You can not add more items than available. In stock ' + this.product.availibilityCount + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    //     return false;
    //   }
    // }
    // else{
    //   product.cartCount = this.count;
    // }
  
  }

  public openProductDialog(event){
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value){
      this.onQuantityChange.emit(value);
  }

}