import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartModel } from '../../pages/cart/cart.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './user.service';
import { CartService } from './cart.service';

@Injectable()

export class WikalaService  {
    baseUrl = environment.DevApiUrl;
    token = localStorage.getItem("token");
    httpOptions = {
     headers: new HttpHeaders({
       // 'Content-Type': 'application/json; charset=utf-8',
       // 'Authorization': "Bearer " + token,
        "Authorization": "Bearer " + this.token,
        "Content-Type": "application/json"
     })
   };
   public cartProducts = [];
   public wishlistProducts = [];
   public count;
   public total;
   public addQuantity;
   public removeQuantity;
   cards : CartModel[] = [];
  constructor(private http: HttpClient,public snackBar: MatSnackBar, public userService :UserService, public Cartservice :CartService) {
   
    this.cartProducts = this.getProductFromCart();
    this.wishlistProducts = this.getProductFromWishlist();
  }

  calculateTotal(){
    let pTotal= 0; 
    this.getProductFromCart().forEach(element => {
      let p;
      if(element.newPrice>0){
       p =element.requiredQuantity*element.newPrice;
      }
      else       
       p =element.requiredQuantity*element.oldPrice;

      

      pTotal=pTotal+p;
    });
    
    this.total =  pTotal;

    return pTotal;
  }
  getCartCount(){
    var count = this.cartProducts.length;
    return count;
  }
  getWishlistCount(){
    return this.wishlistProducts.length
  }
  updateCartProduct(){
    localStorage.removeItem("cartProducts");
    localStorage.setItem("cartProducts", JSON.stringify(this.cartProducts));
  }
  getProductFromCart() {
    if (localStorage.getItem('cartProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('cartProducts'));
  }

  addProductToCart(products: any) {
    let count = 0;
    this.cartProducts.forEach(element => {
      if (products.supplierProductId == element.supplierProductId) {
        count++;
      }
    });
    if (count == 0) {
      this.cartProducts.push(products);
      this.snackBar.open('تم اضافة المنتج الي السلة بنجاح' , '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      this.updateCartProduct()
     
    }
    else {
      this.snackBar.open('هذا المنتج بالفعل في السلة' , '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    } 
    if(this.userService.loggedIn == true){
        this.Cartservice.UpdateList(this.getProductFromCart())
        .subscribe(result => {
          if(result == 'Login Again'){
            this.snackBar.open('برجاء تسجيل الدخول مرة اخري' , '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
          }
        });
    }  
  } 
  updateCartInAPIs():Observable<any>{
    var res=false;
    if(this.userService.loggedIn == true){
      this.cards = [];
      this.cartProducts = this.getProductFromCart();
      this.cartProducts.forEach(element => {
        var pro = new CartModel();
        pro.requiredQuantity = element.requiredQuantity;
        pro.totalCartPrice = element.totalPrice;
        pro.productId = element.productId;
        pro.productMainImage = element.productMainImage;
        pro.productName = element.productName;
        pro.producPriceId = element.producPriceId;
        pro.supplierProductId=element.supplierProductId
        pro.supplierId = element.supplierId;
        pro.id = element.id;
        pro.availibilityCount = element.availibilityCount;
        pro.clintId = element.clintId;
        pro.discount = element.discount;
        pro.newPrice = element.newPrice;
        pro.offerPercentage = element.offerPercentage;
        pro.productIsAvilable = element.productIsAvilable;
        pro.oldPrice = element.oldPrice;
        pro.productName = element.productName;
        pro.quntityIsAvailable = element.quntityIsAvailable;
        pro.totalCartPrice = element.totalCartPrice;
        this.cards.push(pro);
      });
      return this.Cartservice.UpdateList(this.cards)
    }   
  }

  removeProductFromCart(products: any) {
    //return  localStorage.removeItem('cartProducts');
    let count = 0;
    this.cartProducts.forEach((element) => {
      if (products.supplierProductId == element.supplierProductId) {
        var i = this.cartProducts.findIndex(x=>x.supplierProductId == element.supplierProductId);
        this.cartProducts.splice(i, 1);
        count++;
      }
    });
    
    if (count != 0) {
        this.snackBar.open('تم حذف المنتج من السلة بنجاح' , '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

      this.updateCartProduct()
    }
  }
  getProductFromWishlist() {
    if (localStorage.getItem('wishlistProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('wishlistProducts'));
  }
  addProductToWishlist(products: any){
    let count = 0;
    this.wishlistProducts.forEach(element => {
      if (products.supplierProductId == element.supplierProductId) {
        count++;
      }
    });
    if (count == 0) {
      this.wishlistProducts.push(products);
      this.snackBar.open('تمت اضافة المنتج الي قائمة الامنيات' , '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });

      this.updateWishlistProduct()
    }
    else {
        this.snackBar.open('تمت اضافة المنتج بالفعل من قبل' , '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }
  updateWishlistProduct(){
    localStorage.setItem("wishlistProducts", JSON.stringify(this.wishlistProducts));
  }
  removeProductFromWishlist(products){
    let count = 0;
    this.wishlistProducts.forEach((element) => {
      if (products.supplierProductId == element.supplierProductId) {
        this.wishlistProducts.splice(0, 1);
        count++;
      }
    });
    if (count != 0) {
        this.snackBar.open('تم حذف المنتج من قائمة الامنيات' , '×', { panelClass: 'success', verticalPosition: 'top', duration: 3000 });
      this.updateWishlistProduct()
    }
  }
  onAddQuantity(product) {
    this.cartProducts.forEach((element, index) => {
      if (product.supplierProductId == element.supplierProductId) {
        element.quantity++;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.supplierProductId == element.supplierProductId) {
        element.quantity++;
      }
    });
    
    this.updateWishlistProduct()
  }
  savePrice(product) {
    this.cartProducts.forEach((element, index) => {
      if (product.supplierProductId == element.supplierProductId) {
        element.totalPrice = product.totalPrice;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.supplierProductId == element.supplierProductId) {
         element.totalPrice = product.totalPrice;
      }
    });
    
    this.updateWishlistProduct()
  }
  onRemoveQuantity(product) {
    this.cartProducts.forEach((element, index) => {
      if (product.supplierProductId == element.supplierProductId) {
        element.quantity--;
      }
    });
    this.updateCartProduct()

    this.wishlistProducts.forEach((element, index) => {
      if (product.supplierProductId == element.supplierProductId) {
        element.quantity--;
      }
    });
    
    this.updateWishlistProduct()
  }
  // getProductFromAPI(){
  //   debugger; 
  //   if(this.userService.loggedIn == true){
  //     this.updateCartInAPIs()
  //     .subscribe(result => {
  //       this.cartProducts = result;
  //       this.updateCartProduct();
  //       return this.cartProducts;
  //     });
  // }  
  // else{
  //   this.cartProducts = this.getProductFromCart();
  //   return this.cartProducts;
  // }
  // }
}

