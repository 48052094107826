import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularDeviceInformationService } from 'angular-device-information';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  constructor(private deviceInformationService: AngularDeviceInformationService,public appSettings:AppSettings, public router: Router, private spinner: NgxSpinnerService){
    this.settings = this.appSettings.settings;
   
  }

  ngOnInit() {
  
   this.spinner.show();

   setTimeout(() => {
     /** spinner ends after 3 seconds */
     this.spinner.hide();
   }, 0)
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          window.scrollTo(0,0);
      }
    })  
  }
}
