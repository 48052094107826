import { Component, OnInit, Input} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/shared/category-list/category.service';
import { SubCategoryModel } from 'src/app/shared/category-list/models/subcategory';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  subCategories:SubCategoryModel[]=[];
  constructor( private categoryService: CategoryService ,private router:Router,private route:ActivatedRoute ) { 
   
  }
  Menus:any[]=[];
  ngOnInit() {
this.categoryService.GetMenu()
.subscribe(result=>{
  this.Menus = result;
  console.log('this.Menus',this.Menus)
})
    this.categoryService.getAllSubCategorys()
    .subscribe(data=>{
      //  this.categories=data;
        this.subCategories=data;
   
        this.showGroup();
    })
   }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  }
  goToProducts(){
    this.router.navigate(['products/mobiles-and-tablets/tablets'])
  }
  categories: groupeByCategory[] = [];

  showGroup() {
    debugger;

    //First, group the products by category
    const group = this.subCategories.reduce((acc: any, curr) => {
      let key = curr.categoryName;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});

    //Get the categories and product related.
    this.categories = Object.keys(group ).map(key => ({
      category: key,
      subCategories: this.showSubGroup(group[key]) 
    }));
    this.categories.forEach(element => {
      element.subCategories.splice(0,1);
    });
  }
  showSubGroup(subCategories) {
    debugger;
    //First, group the products by category
    const group = subCategories.reduce((acc: any, curr) => {
      let key = curr.parentNameAr;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});

    //Get the categories and product related.
    var sub = Object.keys(group ).map(key => ({
      category: key,
      subCategories: group[key]
    }));
    return sub;
  }
  getCategoryImage(name){
    var item = this.subCategories.find(x=>x.categoryName == name);
    return  item ? item.categoryImage : ''; 
  }
  getCategoryIcon(name){
    var item = this.subCategories.find(x=>x.categoryName == name);
    return  item ? item.categoryIcon : ''; 
  }
  
  checkLength(item){
    if(item.subCategory.length > 4){
      return false;
    }
    return true;
  }
  allCategories:CateforyGroup[]=[];
  sortSubCategory(){
    this.subCategories.forEach(element => {
      
    });
  }
}
class CateforyGroup{
  category?: string;
  subCategories?: any[];
  constructor(item?:CateforyGroup){
    item = item ? item : {};
    this.category = item.category ? item.category : '';
    this.subCategories = item.subCategories ? item.subCategories : [];
  }
}
interface groupeByCategory {
  category: string;
  subCategories: any[];
}
interface groupeBySubCategory {
  subCategory: string;
  subOfSubCategories: any;
}