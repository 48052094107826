import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CategoryModel } from './categort.model';
import { SubCategoryModel } from './models/subcategory';


@Injectable({
    
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAllCategoryes(): Observable<CategoryModel[]> {
 
    return this.http
      .get<CategoryModel[]>(`${this.baseUrl}/Category/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  getAllSubCategorys(): Observable<SubCategoryModel[]> {
 
    return this.http
      .get<SubCategoryModel[]>(`${this.baseUrl}/SubCategory/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetMenu(): Observable<SubCategoryModel[]> {
 
    return this.http
      .get<SubCategoryModel[]>(`${this.baseUrl}/SubCategory/GetMenu`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  getAllSubCategorysByCategoryName(categoryName:string): Observable<SubCategoryModel[]> {
 
    return this.http
      .get<SubCategoryModel[]>(`${this.baseUrl}/SubCategory/GetByCategoryName/${categoryName}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
}
