import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CartModel } from 'src/app/pages/cart/cart.model';
import { CartService } from 'src/app/shared/services/cart.service';


import { WikalaService } from 'src/app/shared/services/wikala.service';

@Component({
  selector: 'app-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss']
})
export class CartDropdownComponent implements OnInit {
  public productAddedToCart: CartModel[] = [];
  public productTotal = 0;
  public count = 0;
  openForm = false;
  isLogin = false;
  public current_language;

  constructor(
    private WikalaService: WikalaService, private cartService: CartService , public toaster: ToastrService) {
    this.current_language = localStorage.getItem('lang');
  }

  ngOnInit() {
    this.productAddedToCart = this.WikalaService.getProductFromCart();  
    const token = localStorage.getItem('token');
    if(token != null){
      this.isLogin = true;
    }

  }
  ngAfterContentChecked(){
    this.productAddedToCart = this.WikalaService.getProductFromCart();    
    this.productTotal = + this.WikalaService.calculateTotal().toFixed(2);
    this.count = this.WikalaService.getCartCount();

  }
  removeProduct(product: any){
    this.WikalaService.removeProductFromCart(product);
    // this.cartService.delete(product)
    // .subscribe(data =>{

    // });
    
  }

}
