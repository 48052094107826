import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PrivcyComponent } from './pages/privcy/components/privcy/privcy.component';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule), data: { breadcrumb: 'إعدادات الحساب' } },
            { path: 'privcy', loadChildren: () => import('./pages/privcy/privcy.module').then(m => m.PrivcyModule), data: { breadcrumb: 'سياسة الخصوصية' } },
            { path: 'returns', loadChildren: () => import('./pages/returns/returns.model').then(m => m.ReturnModule), data: { breadcrumb: 'سياسة المرتجعات' } },
            
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule), data: { breadcrumb: 'مقارنة' } },
            { path: 'wishlist', loadChildren: () => import('./pages/wishlist/wishlist.module').then(m => m.WishlistModule), data: { breadcrumb: 'قائمة الامنيات' } },
            { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'السلة' } },
            { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'اتمام الشراء' } },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'تواصل ' } },
            { path: 'sign-in', loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInModule), data: { breadcrumb: 'تسجيل الدخول ' } },
            { path: 'brands', loadChildren: () => import('./pages/brands/brands.module').then(m => m.BrandsModule), data: { breadcrumb: 'الماركات' } },
            { path: 'products', loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule), data: { breadcrumb: 'كل المنتجات' } },
            { path: 'search-result', loadChildren: () => import('./pages/products/search-result/search-result.module').then(m => m.SearchResultModule), data: { breadcrumb: 'كل المنتجات' } },
            
            { path: 'product', loadChildren: () => import('./pages/products/product/product.module').then(m => m.ProductModule), data: { breadcrumb: 'كل المنتجات'} },
            { path: 'banner-broducts', loadChildren: () => import('./pages/products/banner-broducts/banner-broducts.module').then(m => m.BannerProductsModule), data: { breadcrumb: 'كل المنتجات'} },
            { path: 'slide-broducts', loadChildren: () => import('./pages/products/slide-products/slide-products.module').then(m => m.SlideProductsModule), data: { breadcrumb: 'كل المنتجات'} },

            
        ]
    },
  
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
   preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
   // useHash: true
});