import { Component, ViewEncapsulation, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Data, AppService } from '../../../app.service';
import { Product } from '../../../app.models';
import { ProductsModel } from 'src/app/pages/products/models/products-model';
import { ProductPrice } from 'src/app/pages/products/models/product-price.model';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './product-dialog.component.html',
  styleUrls: ['./product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDialogComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  constructor(public appService:AppService, 
              public dialogRef: MatDialogRef<ProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public product: ProductsModel) { }

  ngOnInit() { 
    if(!this.product.requiredQuantity || this.product.requiredQuantity == 0){
      this.product.requiredQuantity = 1;
    }
  }

  ngAfterViewInit(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      effect: "fade",
      fadeEffect: {
        crossFade: true
      }
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
  changePrice(mysize:ProductPrice){
    this.product.oldPrice = mysize.price;
    this.product.newPrice = mysize.newPrice;
    this.product.oldPrice = mysize.price;
    this.product.productPriceId = mysize.id;
    this.product.availibilityCount = mysize.quantity;
  }
}