import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartModel } from '../../pages/cart/cart.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from './user.service';

@Injectable()

export class CartService  {
    baseUrl = environment.DevApiUrl;
    token = localStorage.getItem("token");
    httpOptions = {
     headers: new HttpHeaders({
       // 'Content-Type': 'application/json; charset=utf-8',
       // 'Authorization': "Bearer " + token,
        "Authorization": "Bearer " + this.token,
        "Content-Type": "application/json"
     })
   };
   public cartProducts = [];
   public wishlistProducts = [];
   public count;
   public total;
   public addQuantity;
   public removeQuantity;

  constructor(private http: HttpClient,public snackBar: MatSnackBar, public userService :UserService) {

  }



  
addTocart(payload: CartModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/Cart/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
}

  
InsertList(payload: CartModel[]): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/Cart/InserList`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
}
  
Save(payload: CartModel): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/Cart/Save`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
}
UpdateList(payload: CartModel[]): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/Cart/UpdateList`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
}
ChickQuantity(payload: CartModel[]): Observable<any> {
    return this.http
      .post<any>(`${this.baseUrl}/Cart/ChickQuantity`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
}

GetCartForClint(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/Cart/GetCartForClint`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
}

delete(payload: CartModel): Observable<boolean> {
  return this.http
    .put<boolean>(`${this.baseUrl}/Cart/Delete`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
}
update(payload: CartModel): Observable<boolean> {
  return this.http
    .put<boolean>(`${this.baseUrl}/Cart/Update`, payload,this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
}
}

