import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SearchResultModel } from './Models/searchResult-model';
import { SearchModel } from './Models/search-model';



@Injectable({
  providedIn: 'root'
})
export class PageService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }

  

  search(payload: SearchModel): Observable<SearchResultModel[]> {
   // console.log(payload);
    return this.http
      .post<SearchResultModel[]>(`${this.baseUrl}/ProductsMaster/Search/`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetProductsForSearch(payload: SearchModel): Observable<SearchResultModel[]> {
    // console.log(payload);
     return this.http
       .post<SearchResultModel[]>(`${this.baseUrl}/ProductsMaster/GetProductsForSearch/`, payload,this.httpOptions)
       .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
  

 
}
