export class CartModel {
    id?: number;
    userId?: string;
    productId?: number;
    supplierProductId?: number;
    productName?: string;
    productMainImage?: string;
    clintId?: number;
    discount?: number;
    producPriceId?: number;
    availibilityCount?: number;
    productIsAvilable?: boolean;
    quntityIsAvailable?: boolean;
    oldPrice?: number;
    newPrice?: number ;
    offerPercentage?: number;
    requiredQuantity?: number;
    totalCartPrice?: number ;
    supplierId?:number;
    // createBy?: string;
    // createDate?: string;
    // isDeleted?: boolean ;
    // deletedDate?: string ;
    // deletedBy?: string;
    constructor(c?:CartModel){
        c=c?c:{};
       this.id=c.id?c.id:0;
       this.userId=c.userId?c.userId:'';
       this.productId=c.productId?c.productId:0;
       this.supplierId=c.supplierId?c.supplierId:0;
       this.supplierProductId=c.supplierProductId?c.supplierProductId:0;
       this.productName=c.productName?c.productName:'';
       this.productMainImage=c.productMainImage?c.productMainImage:'';
       this.clintId=c.clintId?c.clintId:0;
       this.discount=c.discount?c.discount:0;
       this.producPriceId=c.producPriceId?c.producPriceId:0;
       this.availibilityCount=c.availibilityCount?c.availibilityCount:0;
       this.productIsAvilable=c.productIsAvilable?c.productIsAvilable:false;
       this.quntityIsAvailable=c.quntityIsAvailable?c.quntityIsAvailable:false;
       this.oldPrice=c.oldPrice?c.oldPrice:0;
       this.newPrice=c.newPrice?c.newPrice:0;
       this.offerPercentage=c.offerPercentage?c.offerPercentage:0;
       this.requiredQuantity=c.requiredQuantity?c.requiredQuantity:0;
       this.totalCartPrice=c.totalCartPrice?c.totalCartPrice:0;
    //    this.createBy=c.createBy?c.createBy:'';
    //    this.createDate=c.createDate?c.createDate:'';
    //    this.isDeleted=c.isDeleted?c.isDeleted:false;
    //    this.deletedDate=c.deletedDate?c.deletedDate:'';
    //    this.deletedBy=c.deletedBy?c.deletedBy:'';
    }

}