import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { MessagesService } from '../../../pages/contact/messages.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12;

  contactForm: FormGroup;
  andriodLink="https://play.google.com/store/apps/details?id=net.wikala.wikala_app&pcampaignid=web_share";
  iosLink="https://apps.apple.com/eg/app/wikala-%D9%88%D9%83%D8%A7%D9%84%D8%A9/id6474852126 ";
  constructor(private MessagesService:MessagesService) { }
  name:string;
  email:string;
  phone:string;
  message:string;
  ngOnInit() { 
 
  }

  subscribe(){ }
  public onContactFormSubmit():void {
    var values = {id:0,name:this.name,email:this.email,phone:this.phone,message:this.message}
    this.MessagesService.insert(values)
    .subscribe(result =>{
      if(result == true){
        this.ngOnInit();
      }
    })
  }
}