import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from '@angular/core'; 
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from '../app.settings';
import { AppService } from '../app.service';
import { Category, Product } from '../app.models';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import { CategoryService } from '../shared/category-list/category.service';
import { SubCategoryModel } from '../shared/category-list/models/subcategory';
import { HttpClient } from '@angular/common/http';
import { PageService } from './pages.service';
import { SearchResultModel } from './Models/searchResult-model';
import { SearchModel } from './Models/search-model';
import { CartModel } from './cart/cart.model';
import { WikalaService } from 'src/app/shared/services/wikala.service';
import { ControlsComponent } from '../shared/controls/controls.component';
import { UserService } from '../shared/services/user.service';
import { AngularDeviceInformationService } from 'angular-device-information';
import { MobileLinksComponent } from '../shared/mobile-links/mobile-links.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [ SidenavMenuService ],
  
})
export class PagesComponent implements OnInit,AfterViewInit {
  public showBackToTop:boolean = false; 
  public categories:SubCategoryModel[];
  // public data:SearchResultModel[];
  public category:SubCategoryModel;
  public seach :SearchModel={};
  public sidenavMenuItems:Array<any>;
  
  @ViewChild('sidenav', { static: true }) sidenav:any;
  // @ViewChild(ControlsComponent) controls;

  public settings: Settings;
  public productAddedToCart: CartModel[] = [];
  public productTotal = 0;
  count = 0;
  keyword = 'productName';
  fe='في';
  data: any;

  errorMsg: string;
  isLoadingResult: boolean;
  constructor(public appSettings:AppSettings, 
              public appService:AppService, 
              public sidenavMenuService:SidenavMenuService,
              public router:Router,
              private http: HttpClient,
              private categoryesservice :CategoryService,
              private pageService :PageService,
              public userService :UserService,
              public WikalaService:WikalaService,
              private deviceInformationService: AngularDeviceInformationService,
              public dialog: MatDialog, ) { 
    this.settings = this.appSettings.settings; 
  }

  appURL='';
  OS='';
  ngOnInit() {
    this.OS = this.deviceInformationService.getDeviceInfo().os
    if(this.OS == "Android" || this.OS == "iOS"){
      this.openProductDialog();
    }
  
    //console.log("OS")
    //console.log(this.deviceInformationService.isMobile());  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    //console.log(this.deviceInformationService.isTablet());  // returns if the device is a tablet (tablet iPad etc)
    //console.log(this.deviceInformationService.isDesktop()); // returns if the app is running on a Desktop browser.
    //console.log(this.deviceInformationService.getDeviceType()); // returns if the app is running on a Desktop browser.
    //console.log(this.deviceInformationService.getDeviceInfo().os);  // returns os name like Windows/Andtoid/iOS/Linux/Mac OS X etc
    //console.log(this.deviceInformationService.getDeviceInfo().osVersion);  // returns os version like 10/8.1/7 ...etc
    //console.log(this.deviceInformationService.getDeviceInfo().browser);  // returns browser name like chrome/firefox ...etc
    //console.log(this.deviceInformationService.getDeviceInfo().browserVersion);  // returns browser version as number
    //console.log(this.deviceInformationService.getDeviceInfo().browserMajorVersion);  // returns full browser version as number
    //console.log(this.deviceInformationService.getDeviceInfo().screen_resolution);  // returns screen size like 1390x860/640x800 ...etc
    //console.log(this.deviceInformationService.getDeviceInfo().cookies);  // returns cookies enabled or no 
    //console.log(this.deviceInformationService.getDeviceInfo().userAgent);  // returns userAgent
   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh  
    this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
    this.count = this.WikalaService.getCartCount();
    this.productAddedToCart = this.WikalaService.getProductFromCart();
   if(this.productAddedToCart &&  this.productAddedToCart.length > 0){
    console.log('this.productAddedToCart',this.productAddedToCart)
    this.productAddedToCart.forEach(element => {
      element.newPrice = element.newPrice;
    });
 
   }
   
  
  }
  public openProductDialog(){   
    let dialogRef = this.dialog.open(MobileLinksComponent, {
        panelClass: 'product-dialog',
        direction: (this.settings.rtl) ? 'rtl' : 'ltr',
        width: '600px',
        
    });
    dialogRef.afterClosed().subscribe(product => {
  
    });
  }
  AfterViewInit() {
   // this.controls();
  }
  ngAfterContentChecked(){
    // this.productAddedToCart = this.katoraService.getProductFromCart();    
    this.productTotal =  + this.WikalaService.calculateTotal().toFixed(2);
  }
  getServerResponse(event) {
    this.seach.name=event;
    this.isLoadingResult = true;
    this.pageService.search(this.seach).subscribe(data=>{
      if (data== undefined) {
              this.data = [];
              this.errorMsg = data['Error'];
            }
             else {
              this.data = data;
            }
    
    this.isLoadingResult = false;
   // console.log(this.data);
    });

 
    // this.http.get("http://www.omdbapi.com/?apikey=[YOUR_API_KEY]&s=" + event)
    //   .subscribe(data => {
    //     if (data['Search'] == undefined) {
    //       this.data = [];
    //       this.errorMsg = data['Error'];
    //     } else {
    //       this.data = data['Search'];
    //     }
 
    //     this.isLoadingResult = false;
    //   });
  }
 
  goToProducts(item:any)
  {
    this.router.navigateByUrl("/products/"+item.categoryNameEn+"/"+item.subCategoryNameEn);
  }
  searchCleared() {
   // console.log('searchCleared');
    this.data = [];
  }
 
  selectEvent(item) {
    console.log(item)
    this.router.navigate(['search-result',item.productName,item.categoryNameEn])
  }
 
  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
 
  onFocused(e) {
    // do something when input is focused
  }
  public getCategories(){   
    this.categoryesservice.getAllSubCategorys().subscribe(data=>{
      this.categories = data; 
      this.category = data[0];
     
    }); 
    // this.categoryesservice.getAllSubCategorys().subscribe(data => {
    //   this.categories = data;
    //   this.category = data[0];
    //   this.appService.Data.categories = data;
    // })

  }

  public changeCategory(event){
    if(event.target){
      this.category = this.categories.filter(category => category.id == event.target.innerText)[0];
    }
    if(window.innerWidth < 960){
      this.stopClickPropagate(event);
    } 
  }

  public remove(product) {
      const index: number = this.appService.Data.cartList.indexOf(product);
      if (index !== -1) {
          this.appService.Data.cartList.splice(index, 1);
          this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice*product.cartCount;
          this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
          this.appService.resetProductCartCount(product);
      }        
  }

  public clear(){
    this.appService.Data.cartList.forEach(product=>{
      this.appService.resetProductCartCount(product);
    });
    this.appService.Data.cartList.length = 0;
    this.appService.Data.totalPrice = 0;
    this.appService.Data.totalCartCount = 0;
  }
 

  public changeTheme(theme){
    this.settings.theme = theme;       
  }

  public stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  public search(){}

 
  public scrollToTop(){
    var scrollDuration = 200;
    var scrollStep = -window.pageYOffset  / (scrollDuration / 20);
    var scrollInterval = setInterval(()=>{
      if(window.pageYOffset != 0){
         window.scrollBy(0, scrollStep);
      }
      else{
        clearInterval(scrollInterval); 
      }
    },10);
    if(window.innerWidth <= 768){
      setTimeout(() => { window.scrollTo(0,0) });
    }
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
    ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;  
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) { 
        this.sidenav.close(); 
      }                
    });
    this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
  }

  public closeSubMenus(){
    if(window.innerWidth < 960){
      this.sidenavMenuService.closeAllSubMenus();
    }    
  }

}